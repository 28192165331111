import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//引入ele组件库
import ElementUI from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
	// 在每次路由切换前滚动到页面顶部
	window.scrollTo(0, 0);
	next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')