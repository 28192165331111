<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
}
</script>

<style>
body,div,p,span,table,tr,th,td,tbody,thead,tfoot,img,
h1,h2,h3,h4,h5,h6,ul,li,dl,dt,a,input,button,textarea,select{
	margin: 0;
	padding: 0;
}
html,body,#app {
  height: 100%;
  font-size: 14px;
}
.vue-flex {
  display: flex;
}
.flex_items {
  display: flex;
  align-items: center;
}
.flex_content {
  display: flex;
  justify-content: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-items-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex_two_center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.imgAdaptive {
	max-width: 100%;
	height: auto;
}
</style>
