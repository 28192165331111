import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// 判断设备类型的函数
function isMobileDevice() {
	const userAgent = navigator.userAgent.toLowerCase();
	return /iphone|ipod|ipad|android/.test(userAgent);
}

// PC端的路由配置
const pcRoutes = [
	{ path: '/', component: () => import("@/views/index") },
	{ path: '/news', component: () => import("@/views/news") },
	{ path: '/business', component: () => import("@/views/business") },
	{ path: '/about', component: () => import("@/views/about") },
	{ path: '/contact', component: () => import("@/views/contact") },
	{ path: '/article', component: () => import("@/views/article") },
];

// 移动端的路由配置
const mobileRoutes = [
	{ path: '/', component: () => import("@/viewsPhone/index") },
	{ path: '/news', component: () => import("@/viewsPhone/news") },
	{ path: '/business', component: () => import("@/viewsPhone/business") },
	{ path: '/about', component: () => import("@/viewsPhone/about") },
	{ path: '/contact', component: () => import("@/viewsPhone/contact") },
	{ path: '/article', component: () => import("@/viewsPhone/article") },
];

// 根据设备类型选择要使用的路由配置
const selectedRoutes = isMobileDevice() ? mobileRoutes : pcRoutes;

export default new Router({
	routes: selectedRoutes
});